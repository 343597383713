<template>
  <div id="nav">
    <b-navbar toggleable="lg">
      <b-navbar-brand to="/" id="logo-link">
        <div
          class="logo-container"
          :data-version="appVersion"
          :class="{'with-version': appVersion != ''}"
        >
          <img src="images/iq-logo.svg" />
        </div>
      </b-navbar-brand>
      
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>

        <b-navbar-nav class="ml-auto">
          <b-nav-item to="/dashboard">Dashboard</b-nav-item>
          <b-nav-item class="ml-3" to="/profile">Alerts/Filters</b-nav-item>
          <b-nav-item class="ml-3" to="/documentation">Documentation</b-nav-item>
          <b-nav-item
            to="/manage"
            class="ml-3"
            v-if="user.is_admin"
          >Manage</b-nav-item>
          <b-nav-item
            to="/reports"
            class="ml-3"
            v-if="user.is_admin"
          >Reports</b-nav-item>
          <b-nav-item-dropdown right class="ml-5">
            <template #button-content>
              <b-icon icon="person-fill"></b-icon> My Settings
            </template>
            <b-dropdown-item to="/profile">Edit Profile</b-dropdown-item>
            <b-dropdown-item to="/logout">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>

      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TopNav',
  computed: {
    ...mapGetters('auth', [
      'user'
    ]),
    ...mapGetters([
      'appVersion',
    ]),
  },
}
</script>