<template>
  <div id="layout-dashboard">
    <TopNav />
    <router-view></router-view>
  </div>
</template>

<script>
import TopNav from '@/components/TopNav.vue'

export default {
  name: 'DashboardLayout',
  components: {
    TopNav
  },
  data() {
    return {
      loading: true
    }
  },
}
</script>